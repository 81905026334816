import React from 'react'
import ReactDOM from 'react-dom'
import { createGlobalStyle } from 'styled-components'

import Experience from './components/Experience'
import Skills from './components/Skills'
import Welcome from './components/Welcome'

import avenirBook from './static/Avenir-Book.woff2'
import avenirBlack from './static/Avenir-Black.woff2'


const App = () => <>
  <GlobalStyle/>
  <Welcome/>
  <Skills/>
  <Experience/>
</>

const GlobalStyle = createGlobalStyle`

  @font-face {
    font-family: 'Avenir';
    font-display: auto;
    src: local("Avenir"), local("Avenir Next"), url(${avenirBook}) format('woff2');
    font-weight: normal;
    font-style: normal;
  }
  
 
  @font-face {
    font-family: 'Avenir';
    font-display: auto;
    src: url(${avenirBlack}) format('woff2');
    font-weight: 700;
    font-style: normal;
  }
  
  
  body {
    font-family: "Avenir", Helvetica, sans-serif;
    font-size: 17px;
    line-height: 1.6;
    margin: 0;
    @media screen and (max-width: 500px) {
      font-size: 12px;
    }
    @media screen {
      background: radial-gradient(at top left, #565862, #212329) fixed;
      color: white;
    }
  }
  
  #root {
    overflow: hidden;
    padding: 0 5vmin;
  }
  
  h1,
  h2,
  h3 {
    margin: 0;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  h2 {
    letter-spacing: -3px;
    font-size: 80px;
    @media screen and (max-width: 900px) {
      font-size: 50px;
    }
    @media print {
      margin-bottom: 50px;
    }
  }
  
  h3 {
    font-size: 25px;
  }
  
  p {
    line-height: 1;
    margin: 0 0 10px;
  }
`


ReactDOM.render( <App />, document.getElementById( 'root' ) )
