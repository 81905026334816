import React from 'react'
import styled from 'styled-components'

const shapes = [
  'M-873.55 18.63c-124.65 59.17-186.36 229.46-46.13 315.76 96.88 59.62 222.35 13 305.65 105.9 85.66 95.5 19 224.65 153.27 286.66 63.52 29.33 136.9 38.9 206 30.81 67.62-7.93 132.58-43.41 200.83-43.41 134.45 0 147.31 161.9 278.12 168.41 129.85 6.47 232.19-112.89 235.87-236.64 3.39-113.76-75.92-220.17-45.39-335.24C450.55 175.66 575.39 199 677.61 149.63c126.93-61.31 214-193.65 366-91.36 56.17 37.79 89 79.72 162.65 65.41C1281.05 109.15 1352.55 56.89 1401 0H-583c-91.7 0-206.49-21.29-290.55 18.63z',
  'M1006 5c107.62 62.78 164 126.51 247.36-5h-258A73.14 73.14 0 0 1 1006 5zM-878.71 36.11c-70.61 54.6-90.7 156.92-31.76 226.53 45.56 53.8 97.62 41.95 152 72.32 53.4 29.78 85.47 86.38 139.47 117.32 58.25 33.41 65.76 54 84 117.57 18.44 64.42 51.73 101.84 119.4 116.71C-276 717.22-148 637.82-6.28 698.8c82.4 35.45 211.7 197.59 291 78.34 31.62-47.56 36.86-93.59 76.59-138.56 47.05-53.27 47.3-96.9 24.41-163-24-69.3-60-123.5-25.12-196.88 25-52.58 75.47-94.7 127.56-118.41 66.25-30.15 127.07-30.1 189.92-70.62C730.46 55.92 784.91 18 845.25 0H-783c-56.81 0-48.39-.47-95.71 36.11z',
  'M-675.48 0C-661.56 57.32-697 73.28-725 116.14a203.08 203.08 0 0 0-30.49 140.37c19.11 126 111.21 148.9 219.86 138.05 112.75-11.26 165.85 41.31 222.45 129.9 61.08 95.61 160.65 124.68 269.8 131.86C144.59 668.67 364.91 615 338 380.41c-11-96.23-29.8-194.06 63.85-258.3C455.78 85.12 605.06 86.34 609.11 0z',
  'M-599.88 0c-148 134.14-56.19 358.09 145.81 294.81 102.92-32.23 164.92-15.85 220.3 84.31 47.31 85.56 81.88 174.45 168.1 229.47 154 98.28 334.12-12.25 306.41-191.52-12-77.6-39.56-159.21 16.17-225.8C316.8 119.71 415.34 108.42 428.92 0z',
  'M-618.77 0c-98.92 32.75-52.49 133.23 24.27 156.49s159.5-17.17 235.05 15.38c136.45 58.74 113.73 270.4 260.77 314 38.21 11.32 61.4-3.81 97.83-7.94 34-3.86 65.2 13.07 98.47 7.34 91.21-15.7 81.25-112.06 58.13-176.83-37.82-106 21.95-103.87 78.24-180.13C254.14 101 279.82 23.23 244.26 0c-27.4-17.9-127 0-158.73 0h-704.3z',
]

export default function Waves(props) {
  return (
    <Shape {...props} width="1400" height="890">
      <defs>
        <linearGradient id="shapeGradient" x1="40%" x2="100%" y1="0" y2="0">
          <stop offset="0%" stopColor="#F7971E"/>
          <stop offset="100%" stopColor="#FFD200"/>
        </linearGradient>
        <filter id="shadow">
          <feDropShadow dx="10" dy="10" stdDeviation="20" floodColor="#000" floodOpacity=".2"/>
        </filter>
      </defs>
      { shapes.map( ( path, index ) =>
        <path key={ index } filter="url(#shadow)" fill={ `url(#shapeGradient)` } d={ path }/>,
      ) }
    </Shape>
  )
}


const Shape = styled.svg`
  overflow: visible;
  width: 1400px;
`