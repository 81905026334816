import React from 'react'
import styled from 'styled-components'


export default function Section({title, subtitle, children, ...props}) {
  return (
    <Wrapper {...props}>
      {title && <Title>{title} <Subtitle>{subtitle}</Subtitle></Title>}
      <Contents>
        {children}
      </Contents>
    </Wrapper>
  )
}


const Wrapper = styled.section`
  position: relative;
  z-index: 2;
  max-width: 1000px;
  margin: 10vmin auto;
`

const Title = styled.h2`
  margin-left: 10vmin;
  @media screen and (min-width: 1300px), screen and (min-height: 1100px) {
    margin-left: 80px;
  }
  @media screen and (max-width: 500px) {
    margin-left: 20px;
  }
  @media print {
    margin-left: 0;
  }
`

const Subtitle = styled.span`
  opacity: .6;
`

const Contents = styled.div`
  position: relative;
  z-index: 2;
  @media screen {
    padding: 10vmin;
    background: radial-gradient(at top left, #2f303a, #1a1c21);
    box-shadow: 0 50px 100px rgba(0,0,0,.4);
  }
  @media screen and (min-width: 1300px), screen and (min-height: 1100px) {
    padding: 80px;
  }
  @media screen and (max-width: 500px) {
    padding: 20px;
  }
`