import React from 'react'
import styled from 'styled-components'

export default function Skill({id, title, since, description, cons = [], projectsDelivered, logo}) {

  return (
    <Wrapper>

      <SkillIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="100%" height="100%">
        <defs>
          <linearGradient id={'svgGradient' + id} x1="0%" y1="0%" x2="25%" y2="100%">
            <stop offset="5%" stopColor={logo.color1} />
            <stop offset="95%" stopColor={logo.color2} />
          </linearGradient>
        </defs>
        <path fill={`url(#${'svgGradient' + id})`} d={logo.path} />
      </SkillIcon>

      <div>
        <Title>
          <h3>{title}</h3>
          <Experience>
            <Years>{ new Date().getFullYear() - since} years exp.</Years>
            <Projects>{projectsDelivered} projects</Projects>
          </Experience>
        </Title>
        <Description>
          {description.map(line => <p key={line}>{line}</p>)}
          {cons.map(line => <Con key={line}>{line}</Con>)}
          </Description>
      </div>

    </Wrapper>
  )
}


const Wrapper = styled.div`
  display: flex;
  margin-bottom: 50px;
  &:last-child {
    margin-bottom: 0;
  }
`

const SkillIcon = styled.svg`
  @media screen and (min-width: 900px), print {
    flex: 0 0 100px;
    height: 100px;
    margin-right: 20px;
  }
  @media screen and (max-width: 900px) {
    flex: 0 0 50px;
    height: 50px;
    margin-right: 10px;
  }
`

const Title = styled.div`
  @media screen and (min-width: 900px) {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
`

const Description = styled.div`
  opacity: .7;
  p {
    margin: 0;
  }
  @media screen and (max-width: 900px) {
    margin-left: -60px;
    line-height: 1;
    p{
      margin-bottom: 5px;
    }
  }
`

const Con = styled.p`
  color: #ffa270;
`

const Experience = styled.div`
  display: inline-block;
  border-radius: 20px;
  overflow: hidden;
  font-size: 13px;
  > * {
    display: inline-block;
  }
  @media screen and (min-width: 900px) {
    margin-left: 20px;
    background-color: #2d2b33;
    > * {
      display: inline-block;
      padding: 2px 40px 3px 20px;
      border-radius: 20px;
      margin-right: -20px;
    }
  }
  @media screen and (max-width: 900px) {
    margin-top: 5px;
  }
`

const Years = styled.div`

`

const Projects = styled.div`
  @media screen and (min-width: 900px) {
    background-color: #393741;
  }
  @media screen and (max-width: 900px) {
    margin-left: 10px;
  }
`
