import React from 'react'
import styled from 'styled-components'
import Waves from './Waves'


export default function Welcome () {
  return (
    <Wrapper>

      <Shape/>

      <Contents>
        <Title>Hey.</Title>
        <Introduction>
          My name is Guillaume Cièr, I'm a<br/>
          <EngineerText>full-stack engineer</EngineerText> & <Designer>product designer</Designer>
        </Introduction>
      </Contents>

    </Wrapper>
  )
}


const Wrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;
  padding: 250px 0 50px;
`

const Shape = styled(Waves)`
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  @media screen and (min-width: 1200px) {
    transform: translateX(-140px);
  }
  @media screen and (max-width: 1200px) {
    left: -400px;
  }
  @media print {
    transform: translateY(-300px);
    path {
      fill: white;
    }
  }
`

const Contents = styled.div`
  position: relative;
  z-index: 2;
  text-align: center;
`

const Title = styled.div`
  font-size: 110px;
  font-weight: bold;
  line-height: .8;
  @media screen and (max-width: 700px) {
    font-size: 60px;
  }
`

const Introduction = styled.p`
  font-size: 25px;
  margin-top: 30px;
  line-height: 1;
  @media screen and (max-width: 700px) {
    font-size: 16px;
  }
`

const EngineerText = styled.span`
  font-family: monospace;
  font-size: 80%;
  background-color: #333;
  border-radius: 3px;
  padding: 2px 8px;
  @media print {
    background-color: #eaeaea;
  }
`

const Designer = styled.span`
  color: #ff8100;
`