import React from 'react'
import Skill from './Skill'

import { skillsList } from '../static/data'
import Section from './Section'


export default function Skills () {
  return skillsList.map( skillGroup =>
    <Section key={ skillGroup.title } title={ skillGroup.title } subtitle="skills">
      { skillGroup.skills.map( skill => <Skill key={ skill.title } { ...skill }/> ) }
    </Section>,
  )
}
