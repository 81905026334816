
export const getDateDiff = (from, to) => {

  const startDate = new Date(from)
  const endDate = new Date(to)

  const months =
    ( endDate.getFullYear() - startDate.getFullYear() ) * 12
    + ( endDate.getMonth() - startDate.getMonth())

  return {
    'years': Math.floor(months / 12).toFixed(),
    'months': ( months % 12 ).toFixed(),
  }
}

export const pluralize = (amount, singularText, pluralText) => {
  return (amount > 1) ? `${amount} ${pluralText}` : `${amount} ${singularText}`
}

export const formatDate = date => {

  const dateFormater = new Intl.DateTimeFormat('en-US', {
    month: 'short',
    year: 'numeric'
  })

  return dateFormater.format(new Date(date));
}