import React from 'react'
import styled from 'styled-components'

import { experiencesList } from '../static/data'
import { formatDate, getDateDiff, pluralize } from '../helpers'
import Section from './Section'


export default function Experience () {

  return (
    <Section title="Experience">

      { experiencesList.map( ( { id, company, job, from, to, description, logoPath, color1, color2 } ) => {

        const { years, months } = getDateDiff( from, to )

        return <Wrapper key={ id }>

          <Title>
            <Logo
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 240 50"
              role="img"
              aria-labelledby="title"
            >
              <title id="title">{ company }</title>
              <defs>
                <linearGradient id={ 'logoGradient' + id }>
                  <stop offset="5%" stopColor={ color1 }/>
                  <stop offset="95%" stopColor={ color2 }/>
                </linearGradient>
              </defs>
              <path fill={ `url(#${'logoGradient' + id})` } d={ logoPath }/>
            </Logo>

            <Dates>
              <DateRange>
                <span>{ formatDate( from ) }</span>
                <span>{ formatDate( to ) }</span>
              </DateRange>
              <Duration>
                { years > 0 && pluralize( years, 'year', 'years' ) }
                { years > 0 && months > 0 && ' & ' }
                { months > 0 && pluralize( months, 'month', 'months' ) }
              </Duration>
            </Dates>

          </Title>

          <Contents>
            <h3>{ job }</h3>
            <Description>
              { description.map( ( line, index ) => <p key={ index }>{ line }</p> ) }
            </Description>
          </Contents>

        </Wrapper>

      } ) }

    </Section>
  )
}


const Wrapper = styled.section`
  @media screen and (min-width: 800px), print {
    margin-bottom: 100px;
    display: flex;
  }
  @media screen and (max-width: 800px) {
    margin-bottom: 60px;
  }
  &:last-child {
    margin-bottom: 0;
  }
`

const Logo = styled.svg`
  @media screen and (max-width: 800px) {
    height: 30px;
  }
`

const Title = styled.div`
  flex: 0 0 240px;
  margin-right: 5vmin;
`

const Dates = styled.div`
  @media screen and (max-width: 800px) {
    display: flex;
    margin-bottom: 10px;
  }
`

const DateRange = styled.div`
  span:first-child:after {
    content: '⟶';
    margin: 0 10px;
    opacity: .5;
  }
`

const Duration = styled.div`
  opacity: .5;
  @media screen and (max-width: 800px) {
    margin-left: 10px;
  }
`

const Contents = styled.div`
  flex: 1;
  h3 {
    margin-bottom: 10px;
  }
`

const Description = styled.div`
  opacity: .6;
`

